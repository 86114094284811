<template>
  <dash-page-new
      :root="$t('Dashboard')"
      :subtitle="$store.state.settings.pageData.subtitle || $t('Documentflow')"
      :title="$store.state.settings.pageData.title || $t('Projects')"
      :icon="$store.state.settings.pageData.icon || 'mdi-cogs'"
      :filters="$store.state.settings.pageData.filters || {}"
      :header-route="$store.state.settings.pageData.headerRoute || null"
      :filters-input="$store.state.settings.filtersData || []"
      :side-navigation="navigationSelect"
      @filter="$store.state.settings.filtersData = $event"
      navigation-route="documentflow"

  >

    <template #header_action.desktop>
      <div class="d-flex align-center">

        <ft-select
            v-if="($store.state.settings.pageData.headerAction || !!$store.state.settings.pageData.headerDropdownActions) && ($store.state.settings.pageData.headerActionCondition === null || $store.state.settings.pageData.headerActionCondition)"
            :items="$store.state.settings.pageData.headerDropdownActions || []">
          <ws-button
              @click="!$store.state.settings.pageData.headerDropdownActions ? $store.state.settings.pageData.headerAction() : () => {}"
              :label-raw="$store.state.settings.pageData.headerActionText"
              :click-stop="!$store.state.settings.pageData.headerDropdownActions"
              :icon="!!$store.state.settings.pageData.headerDropdownActions ? 'mdi-menu-down' : null"
          />
        </ft-select>

        <ws-button-group
            v-if="$store.state.documentflow.displaySignatureTabs"
            v-model="$store.state.documentflow.displaySigned"
            :items="signatureTabsSelect"
        >

        </ws-button-group>

      </div>
    </template>

    <template #default>
      <router-view></router-view>
    </template>


  </dash-page-new>
</template>

<script>
export default {
  name: "BusinessProjectManager",
  data() {

  },
  computed : {
    signatureTabsSelect() {
      return [
        { text : this.$t('documentflow.for_signature') , value : null },
        { text : this.$t('Signed') , value : true }
      ]
    },
    navigationSelect() {
      return [
        { text : this.$t('Documents')    ,
          path : '' , value : '' ,
          icon : 'mdi-file-document-multiple-outline'
        },
        { text : this.$t('documentflow.for_signature') ,
          path : 'signature'    ,
          value : 'signature',
          counter : this.$store.state.documentflow.signatureRequestsCount,
          icon : 'mdi-draw'
        },
        { text : this.$t('Declined') ,
          path : 'declined'    ,
          value : 'declined',
          counter : this.$store.state.documentflow.declinedDocumentsCount,
          icon : 'mdi-alert'
        },
        { text : this.$t('Overdue') ,
          path : 'overdue'    ,
          value : 'overdue',
          counter : this.$store.state.documentflow.overdueDocumentsCount,
          icon : 'mdi-timer-alert-outline'
        },
        { text : this.$t('Settings')     ,
          path : 'settings' ,
          value : 'settings' ,
          icon : 'mdi-cog'
        },
      ]
    }
  },
  methods : {
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>